/*===============
  スムーススクロール
===============*/
$(function () {
	$('a[href^="#"]').click(function () {
		var adjust = 60;
		var speed = 400;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top - adjust;
		$('body,html').animate({
			scrollTop: position
		}, speed, 'swing');
		return false;
	});
});
/*===============
  nav
===============*/
$(function () {
	$(".header-logo_block.hum").on("click", function () {
		$(".header-logo_block.hum").toggleClass("active");
		$(".header_area.menu").toggleClass("active");
		$("html,body").toggleClass("nav-overflow");
	});
});


$(function () {

	$(".spMenu__spSubListItem a").on("click", function () {
		$(".header-logo_block.hum").removeClass("active");
		$(".header_area.menu").removeClass("active");
		$("html,body").removeClass("nav-overflow");
	});
});


/*===============
  ページ読み込み時フェード
===============*/
window.onload = function () {
	$("body").addClass("load");
}



/* =================
画面内に入ったらフェードで表示
================= */
const fades = gsap.utils.toArray('.fade');
fades.forEach((fade) => {
	gsap.fromTo(fade, {}, {
		scrollTrigger: {
			trigger: fade.parentNode,
			toggleClass: {
				targets: fade,
				className: "fade_active"
			},
			//    markers: true,
			once: true,
			start: 'center bottom'
		}
	});
});
const fade_ups = gsap.utils.toArray('.fade_up');
fade_ups.forEach((fade_up) => {
	gsap.fromTo(fade_up, {}, {
		scrollTrigger: {
			trigger: fade_up.parentNode,
			toggleClass: {
				targets: fade_up,
				className: "fade_up_active"
			},
			//    markers: true,
			once: true,
			start: 'center bottom'
		}
	});
});


/* =================
aboutus FV動画用フェード
================= */
gsap.fromTo('.firstview_area.bg', {
	autoAlpha: 1,
}, {
	//  duration: 1.4,
	autoAlpha: 0,
	scrollTrigger: {
		trigger: 'section#firstview',
		start: 'bottom bottom',
		end: 'bottom top',
		scrub: true,
		//markers: true,
	}
})



/* =================
pagetop用フェード
================= */

gsap.to('section#pagetop', {
	scrollTrigger: {
		onEnter: () => func01(),
		onLeaveBack: () => func02(),
		trigger: 'body',
		start: 'top+=500',
		scrub: true,
		toggleActions: "play pause resume reset",
		// markers: true,
	}
})

function func01() {
	$("#pagetop").addClass('visible');
};

function func02() {
	$("#pagetop").removeClass('visible');
};

/* =================
PCヘッダー用格納
================= */
var beforePos = 0;
var elemTop = $('#flag').offset().top;

//スクロール途中でヘッダーが消え、上にスクロールすると復活する
function ScrollAnime() {
	var scroll = $(window).scrollTop();

	if (scroll == beforePos) {
		//IE11対策で処理を入れない
	} else if (elemTop > scroll || 0 > scroll - beforePos) {
		$('#pcHeader').removeClass('UpMove');
		$('#pcHeader').addClass('DownMove');
	} else {
		//ヘッダーが上に消える
		$('#pcHeader').removeClass('DownMove');
		$('#pcHeader').addClass('UpMove');
	}
	beforePos = scroll; //現在のスクロール値を比較用のbeforePosに格納
}

$(window).scroll(function () {
	ScrollAnime(); //スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
});


/* =================
ヘッダー色変更
================= */
gsap.to('#flag', {
	scrollTrigger: {
		onEnter: () => head01(),
		onLeaveBack: () => head01(),
		trigger: '#flag',
		start: 'top',
		scrub: true,
		toggleActions: "play pause resume reset",
		//markers: true,
	}
})

function head01() {
	$("#pcHeader").toggleClass('colorred');
};


/* =================
各種ページヘッダービジュアル用フェード
================= */

gsap.fromTo('.contents_header_area.bg', {
	autoAlpha: 1,
}, {
	//  duration: 1.4,
	autoAlpha: 0,
	scrollTrigger: {
		trigger: 'section#flag-=100',
		start: 'center center',
		end: 'bottom top',
		scrub: true,
		//    markers: true,
	}
})
